#autoketing-custom-design {
  position: fixed;
  opacity: 0;
  bottom: -200px;
  padding: 3%;
  z-index: 2147483646;
}

[data-popup-state="open"] #autoketing-custom-design{
  opacity: 0.98;
  bottom: 0px;

  -webkit-animation-name: flipInX; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.8s; /* Safari 4.0 - 8.0 */
  animation-name: flipInX;
  animation-duration: 0.8s;
}

[data-popup-state="close"] #autoketing-custom-design{
  opacity: 0;
  bottom: -200px;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition-duration: 2s;
}

#autoketingAK1{
  display: flex;
  background: linear-gradient(to left top, #faa, #afa, #aff);
}

@media only screen and (max-width: 749px){
  #autoketingProductImage{
    padding: 5px;
    max-width: 100px;
  }  
}

@media only screen and (min-width: 750px){
  #autoketingProductImage{
    padding: 5px;
    max-width: 120px;
  }  
}

#autoketingPopupContent {
  width: 100%;
  height: 100%;
}

#autoketingClosePopup{
  float: right;
  color: #f00;
  margin-right: 7px;
  cursor: pointer;
}

a#autoketingPopupClick {
  color: maroon;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

#autoketingDivPopupClick {
  cursor: pointer;
}

div#autoketingTitlePopup {
  color: lightslategray;
  font-weight: 600;
  font-style: italic;
  margin-right: 22px;
}

div#autoketingDivTimeAgo {
  float: left;
}

div#autoketingDivTimer, div#autoketingDivStock {
  float: right;
  margin-right: 5px;
  color: teal;
  font-size: 13px;
  font-weight: bolder;
}

div#autoketingTimeAgo {
  color: teal;
  font-size: 12px;
  font-weight: bold;
}

#autoketingVerified{
  float: right;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(500px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(500px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(500px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(500px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(500px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(500px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(500px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(500px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(500px);
    transform: perspective(500px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(500px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(500px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(500px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(500px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(500px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(500px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(500px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(500px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(500px);
    transform: perspective(500px);
  }
}
